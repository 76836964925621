html {
  scroll-behavior: smooth;

}
.App {
  font-family: 'Montserrat', sans-serif;
  background-color: #00004d;
  color: white;
  font-family: 'Montserrat', sans-serif;
  overflow-x: hidden;
}


#page1 {
  height: 1000px;
  display: flex;
  justify-content: center;
  color: white;

}


#page1_text {

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 50px;
  text-align: center;
  line-height: 1.2em;

}
#buttonTo2nd {
  border-radius: 2px;
  background-color: Transparent;
  border: 2px solid #cb0065;
  font-family: 'Montserrat', sans-serif;
  color: white;
  padding: 10px 15px 10px 15px;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
}
#buttonTo2nd:hover {
  background-color:  #cb0065;
}
#buttonTo2nd:focus, #buttonTo2nd:active{
  outline: none;
}
a{
  text-decoration: none;
}



#nav {
  overflow: hidden;
  width: 95%;
  display: flex;
  padding: 15px 0px 15px 100px;
  background-color:#200057;
  transition: 0.25s;
  z-index: 100;
}
#nav a {
  padding-right: 50px;
  font-size: 20px;
  color: white;
  font-family: 'Montserrat', sans-serif;
}
#nav a:hover, .nav a:focus, .nav a:active{
  color: #ff1a8c;
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%;

  
}
#page2 {
  padding: 153px 0px 153px 0px;
}

#page3{
  padding-top: 100px;
  padding-bottom: 100px;
}
#title, #subtitle {
  color: white;
  font-weight: bold;
  font-size: 50px;
  display: flex;
  justify-content: center;
  
}
#subtitle {
  margin-top: 50px;
  font-size: 30px;
  margin-left: 50px;

}

#page2_content {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  padding-top: 10px;
  justify-content:space-evenly;

}

#picture{
  border-radius: 0px;
  background: #00004d;
  box-shadow: inset 21px 21px 42px #00003e, 
              inset -21px -21px 42px #00005c;
  width: 300px;
  height: 300px;
  padding: 15px;
  border-radius: 400px;
  animation: ease-in;
  animation-duration: 2s;
  border: ridge 1px white;
  opacity: 0.5;
  object-fit:contain;
}
#picture:hover {
  opacity: 1;
  animation: ease-in;
  animation-duration: 2s;
}

#pesonal_pic {
  text-align: right;
  border-radius:  2px 2px 2px;
}
#page2_text {
  text-align: center;
  padding-top: 100px;
  font-weight: bold;
  color: white;
  line-height: 2em;
  font-size: 20px;
}
#page2_text a {
  color: #ff1a8c;
  position: relative;

}

#page2_text a:before {
  content: "";
  position: absolute;
  width: 0;
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #ff1a8c;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}
#page2_text a:hover:before {
  visibility: visible;
  width: 100%;
}
#skills {
  color: white;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: 100px;
  font-size: 18px;
}
#skillsTitle {
  color: #cb0065;
  font-weight: bold;
  margin-left: 100px;
  font-size: 18px;
}
#skills_container {
  display: flex;
  flex-direction: column;
  margin-right: 50px;
}

#page3 {
  background-color: #000034;
  font-family: 'Montserrat', sans-serif;

}
#filtersContainer {
  display: flex;
  justify-content: center;
  margin-top: 60px;

}
.btn {
  border: none;
  outline: none;
  padding: 12px 16px;
  margin-right: 10px;
  background-color: #000034;
  color: white;
  font-size: 25px;
  font-weight: bold;
}
.btn:hover{
  background-color:#000067;
}
.btn.active {
  background-color:#de006f;
}
.Projects {
  display: flex;
  object-fit: contain;
  width: 420px;
  justify-content: center;

  display: none;
}
.gallery {
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.show {
  display: flex;
  flex-direction: row;
}
#ProjectImg {
  object-fit: fill;
  height: 400px;
    opacity: 1;
    display: flex;
      transition: .5s ease;
    backface-visibility: hidden;
  
}

#ProjectImg:hover {
  opacity:0;
}


.middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  color: white;
  font-weight: normal;
  text-align: center;
  padding-top: 60px;
}
.Projects:hover #ProjectImg {
  opacity: 0;
}

.Projects:hover .middle {

  opacity: 1;
}
#ProjectsSubtitle {
  font-size: 20px;
  color:#cb0065;
  margin-bottom: 100px;
}
#ProjectsTitle {
  font-size: 30px;
}
#descriptionBtn {
  border: 1px solid #cb0065;
  padding: 10px 15px 10px 15px;
}
#descriptionBtn:hover {
  background-color: #cb0065;
  cursor: pointer;
}
#overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 2;
  cursor: pointer;
 
}
.overlay_content {
  border-radius: 5px;
  background: #00004d;
  box-shadow: inset 5px 5px 10px #000041, 
              inset -5px -5px 10px #000059;
  color: white;
  display: none;
  width: 30%;
  text-align: center;
  padding: 50px;
  margin-top: 200px;
  
}
.overlay_content a {
  color: white;
}
#overlay_container {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
#closeOverlay {
  color: white;
  position: absolute;
  right: 34%;
  font-size: 20px;
}
#closeOverlay:hover {
  color: #c30865;
}
#page4 {
  background-color: #00004d;
  font-family: 'Montserrat', sans-serif;
  padding-top: 180px;
  color: white;
  font-weight: bold;
  border-right: 1px solid white;
}
#page4_sub {
  display: flex;
  justify-content: center;
  margin-top: 60px;
  font-size: 40px;
  color: #cb0065;
}

#email {
  display: flex;
  justify-content: center;
  color: white;
  padding-bottom: 100px;
}


#emailBtn {
  color: white;
  margin-top: 50px;
  border: 1px solid #cb0065;
  padding: 10px 10px 10px 10px;
  border-radius: 2px;
  font-weight: normal;
  font-size: 25px;
}

#emailBtn:hover {
  background-color: #c30865;
}

footer {
  color: white;
  padding-top: 50px;
  padding-bottom: 50px;
  margin-top: 100px;
  background-color:#000034; 
  border-top: 1px solid #c30865;
}

#mediaLinks {
  display: flex;
  justify-content: center;
}
#mediaLink {
  margin-right: 20px;
  font-size: 55px;
}
#mediaLink:hover {
  color: #c30865;
}
#mediaLinks a {
  text-decoration: none;
  color: white;
}
footer p {
  display: flex;
  justify-content: center;
  font-weight: normal;
  margin-top: 50px;
}
.slide-in-left{animation:slide-in-left 1s cubic-bezier(.25,.46,.45,.94) both}
@keyframes slide-in-left{0%{transform:translateX(-1000px);opacity:0}100%{transform:translateX(0);opacity:1}}
.first_img {
  width: 100%;
  height: 100%;
}
.scale-in-center{animation:scale-in-center 1.6s cubic-bezier(.25,.46,.45,.94) both}
@keyframes scale-in-center{0%{transform:scale(0);opacity:1}100%{transform:scale(1);opacity:1}}

.slide-in-bottom0{animation:slide-in-bottom 1s cubic-bezier(.25,.46,.45,.94) both}
.slide-in-bottom1{animation:slide-in-bottom 1.4s cubic-bezier(.25,.46,.45,.94) both}
.slide-in-bottom2{animation:slide-in-bottom 1.6s cubic-bezier(.25,.46,.45,.94) both}
.slide-in-bottom3{animation:slide-in-bottom 1.8s cubic-bezier(.25,.46,.45,.94) both}
.slide-in-bottom4{animation:slide-in-bottom 2s cubic-bezier(.25,.46,.45,.94) both}
.slide-in-bottom5{animation:slide-in-bottom 2.4s cubic-bezier(.25,.46,.45,.94) both}

@keyframes slide-in-bottom{0%{transform:translateY(1000px);opacity:0}100%{transform:translateY(0);opacity:1}}

@media only screen and (max-width: 600px) {
  #page1_text {
    font-size: 30px;
  }
  #buttonTo2nd {
    font-size: 15px;
  }
  #title {
    font-size: 35px;
  }
  #page2_content {
    flex-direction: column-reverse;
    justify-content: center;
    text-align: center;
  }
  #page2_text {
    font-size: 12px;
    padding-top: 30px;
  }
  #picture{
    width: 190px;
    height: 190px;
  
  }
  #personal_pic {
    text-align: center;
    margin-top: 20px;
  }
  #skills, #skillsTitle {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
    font-size: 15px;
  }
  .btn {
    font-size: 12px;
  }
  
  .Projects {
    width: 100%;
  }
  #ProjectImg {
    height: 100%;
    object-fit: fill;
    margin-bottom: 100px;
  
  }
  #gallery {
    margin-left: 0px;
  }
  #ProjectsTitle {
    font-size: 25px;
  }
  #ProjectsSubtitle{
    font-size:15px;
    margin-bottom: 50px;
  }
  #page4_sub{
    font-size: 30px;
  }
  #emailBtn {
    font-size: 15px;
  }
  #nav {
    justify-content:center;
    padding-left: 0px;
    width: 100%;
  }
  #nav a {
    font-size: 12px;
  }
  #page2 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  #page4 {
    padding-top: 80px;
  }
  #overlay_container {
    margin-top: 0px;
  }
  .overlay_content {
    margin-top: 100px;
    width: 70%;
  }
  #closeOverlay {
    right: 10%;
  }
  footer {
    padding-bottom: 30px;
  }
  .middle {
    background-color: #000034;
    height: 290px;
    width:100%;
  }
  #page1 {
    height: 710px;
  }
}